import styled, { css } from 'styled-components';

import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import shadow from '../../atoms/shadows';
import { font } from '../../atoms/typography';

export const customInnerSectionStyles = css`
  padding-top: 115px;

  ${atMinWidth.md`
    padding-top: 139px;
  `}

  ${atMinWidth.lg`
    padding-top: 147px;
  `}
`;

export const DetailContainer = styled.div`
  .side-bar {
    @media (max-width: 991px) {
      display: none;
    }
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 16px;
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${colors.primary[500]};
`;

export const Heading = styled.h1`
  ${font('display', 'lg', '700')};
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'xl', '700')};
  `}

  ${atMinWidth.md`
    ${font('display', 'xxl', '700')};
  `}
`;

export const Subtitle = styled.p`
  ${font('display', 'sm', '700')}
  color: ${colors.gray[500]};

  ${atMinWidth.md`
    ${font('display', 'md', '700')}
  `}
`;

export const Subhead = styled.p`
  ${font('display', 'sm', '700')}
  color: ${colors.gray[500]};

  ${atMinWidth.md`
    ${font('display', 'md', '700')}
  `}
`;

export const ImgRef = styled.div`
  max-width: 100%;
  margin: 0 auto;
  ${shadow('xl')}
  border-radius: 15px;
  background: ${colors.base.white};
  text-align: center;

  ${atMinWidth.lg`
    max-width: 470px;
  `}
`;

export const ContentWrapper = styled.section`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
  }
  hr {
    margin: 56px 0;
    @media (max-width: 768px) {
      margin: 48px 0;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 22.5px;
    line-height: 150%;
    letter-spacing: -0.27px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #6f7283;
  }
  @media (max-width: 768px) {
    padding: 64px 0px;
    p {
      font-size: 16px;
      line-height: 150%;
    }
  }
  .content-bottom {
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 35.16px;
      line-height: 110%;
      letter-spacing: -0.3px;
      color: #1d2030;
      margin-bottom: 32px;
      @media (max-width: 991px) {
        font-size: 35.16px;
      }
      @media (max-width: 768px) {
        font-size: 28.13px;
      }
    }
  }
`;

export const RelatedIntegrationStyles = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  h2 {
    @media (min-width: 991px) {
      margin-top: 32px !important;
    }
  }
  h5 {
    font-weight: 500;
    margin-bottom: 24px;
  }

  .integration-block {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
  }

  figure {
    width: 64px;
    height: 64px;
    margin-bottom: 0;
    margin-right: 16px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  p {
    ${font('text', 'sm', '500')}
    max-width: 166px;
    margin-bottom: 0;
    color: var(--text-color);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
