import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RelatedIntegrationStyles } from '../../styles/Components/Integrations/IntegrationsDetailStyle';
import Img from '../../utils/OptimizedImage';
import Link from '../../utils/Link';

const RelatedIntegrations = ({ id, category }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          blogs: allDatoCmsIntegrationPage(
            sort: { fields: meta___createdAt, order: DESC }
          ) {
            nodes {
              id
              title
              subtitle
              slug
              category {
                name
                id
                slug
              }
              integrationImage {
                internalName
                image {
                  gatsbyImageData(
                    imgixParams: {
                      fm: "webp"
                      auto: "compress"
                      maxW: 1080
                      fit: "clip"
                      q: 35
                    }
                  )
                  url
                  fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                    ...GatsbyDatoCmsFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const relatedIntegrations = data.blogs.nodes
          .filter(item => item.id !== id && item.category.name === category)
          .slice(0, 3);

        if (!relatedIntegrations.length) {
          return;
        }

        return (
          <RelatedIntegrationStyles>
            <h5>Related Integrations</h5>
            {relatedIntegrations.map(integration => (
              <Link
                to={`/integrations/${integration.slug}`}
                key={integration.id}
              >
                <div className="integration-block">
                  <figure>
                    {integration?.integrationImage?.image?.gatsbyImageData ? (
                      <Img
                        image={
                          integration?.integrationImage?.image?.gatsbyImageData
                        }
                        alt={integration?.internalName}
                        title={integration?.integrationImage?.image.title}
                      />
                    ) : (
                      <Img
                        src={integration?.integrationImage?.image.url}
                        alt={integration?.internalName}
                        title={integration?.integrationImage?.image.title}
                      />
                    )}
                  </figure>
                  <p>{integration.title}</p>
                </div>
              </Link>
            ))}
          </RelatedIntegrationStyles>
        );
      }}
    />
  );
};

export default RelatedIntegrations;
